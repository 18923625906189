.refreshBtn {
    position: absolute;
    bottom: 296px;
    left: 32%;
    z-index: 10;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    border-radius: 24px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
    span {
        color: var(--G6, #433E48);
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
        margin-left: 4px;
    }
}
.currentPosBtn {
    position: absolute;
    bottom: 296px;
    right: 16px;
    z-index: 10;
    width: 44px;
    height: 44px;
    border-radius: 44px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
}
.brandListWrapper {
    width: 100%;
    background-color: #fff;
    position: absolute;
    bottom: 85px;
    z-index: 11;
    transition: height 0.3s ease;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
    .max {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
}
.brands {
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    display: none;
  }
}
.top {
    width: 100%;
    height: 20px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .grap {
        width: 40px;
        border-radius: 2px;
        height: 4px;
        background: #D2D0D5;
    }
}
.tabWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--G2, #E9E7EC);
    padding: 12px 20px;
}
.tab {
    padding: 10px 20px;
    color: var(--G6, #433E48);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    border-radius: 30px;
    border: 1px solid var(--G2, #E9E7EC);
    background: var(--W, #FFF);
    margin-right: 4px;
    &.selected {
      background: #000;
      color: #fff;
    }
}
.currentPosWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  .loop {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../assets/icons/loop.svg') no-repeat center / cover;
    cursor: pointer;
    margin-right: 4px;
  }
  .currentPosition {
    color: var(--color-pink, #ff4b84);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.36px;
    margin-right: 8px;
  }
}
.addr2 {
  color: var(--black, var(--color-black, #000));
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  letter-spacing: -0.36px;
}
.addr3 {
  color: var(--black, var(--color-black, #000));
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  letter-spacing: -0.36px;
}

.benefits {
  // padding: 16px 20px;
  padding-left: 16px;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--P, #ff4b84);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
}

.custom_checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 8px;
}

.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  height: 24px;
  width: 24px;
  display: block;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  background: #fff;
  border: 1px solid #e9e7ec;
}

.custom_checkbox input:checked ~ .checkmark {
  background-color: #433e48;
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.custom_checkbox input:checked ~ .checkmark::after {
  display: block;
}

.custom_checkbox .checkmark::after {
  left: 7px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.newpick {
  background: #ebf3fa;
  color: #124778;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100%;
  span {
    margin: 0 6px;
  }
}

.keywordSelectorWrapper {
  position: sticky;
  bottom: 125px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 16px;
  .keywordSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    .keyword {
      width: 64px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f7f7fc;
      &.keyword_left {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &.keyword_right {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &.selected {
        background: #000;
        color: #fff;
      }
    }
  }
}
.noListWrapper {
  display: flex;
  justify-content: center;
}
.noList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  .noListMsg {
    color: var(--gray5, var(--color-gray-5, #78737D));
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
    margin: 12px 0;
  }
  .noListBtn {
    border-radius: 5px;
    border: 1px solid var(--color-gray-2, #E9E7EC);
    background: var(--color-white, #FFF);
    padding: 12px;
    color: var(--6, var(--color-gray-6, #433E48));
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
  }
  .registerBrand {
    color: var(--4, #9f9ca3);
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    letter-spacing: -0.28px;
    margin-top: 12px;
    .dropdownIcon {
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url('/assets/icons/brand_info_area_selection_down.svg')
        no-repeat center / cover;
    }
    .registerArrow {
        flex: none;
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url('/assets/icons/arrow_dropdown.svg') no-repeat center /
          cover;
    }
  }
}
.mapBtn {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
}