.modalWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 12;
}

.modalContents {
  position: absolute;
  /* bottom: -500px; */
  padding: 0 20px;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 20px 20px 20px;
  width: 375px;
  height: 415px;
  z-index: 12;
  &.ios {
    padding: 10px 20px 50px 20px;
  }
  &.active {
    animation: slideUp 0.5s ease;
  }
  &.close {
    transform: translateY(500px);
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    .headerIcon {
      display: inline-block;
      background: url('/assets/icons/brand_address_selector_down_icon.svg')
        no-repeat center / cover;
      width: 16px;
      height: 8px;
      cursor: pointer;
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.modalCloseHeader {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding: 8px 0 16px;

  .headerTitle {
    margin-right: 20px;
    flex: 1;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
}

@keyframes slideUp {
  from {
    /* 하위 120px 에서 시작합니다. */
    transform: translateY(500px);
  }
  to {
    /* 원 위치로 이동합니다. */
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    /* 하위 120px 에서 시작합니다. */
    transform: translateY(0);
  }
  to {
    /* 원 위치로 이동합니다. */
    transform: translateY(500px);
  }
}

@media screen and (max-width: 600px) {
  .modalWrapper .modalContents {
    width: 100% !important;
    max-height: none;
  }
}
