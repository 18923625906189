.wrapper {
  z-index: 11;
  display: flex;
  position: sticky;
  justify-content: center;
  height: 85px;
  // width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #e9e7ec;
}
.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 16px;
  border: 0;
  background: #fff;
  .icon {
    width: 20px;
    height: 20px;
  }
}
.txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #9f9ca3;
  margin-top: 8px;
  &.active {
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }
}
.home-active-icon {
  background: url('/assets/icons/home-active-icon.svg') no-repeat center;
}
.home-deactive-icon {
  background: url('/assets/icons/home-deactive-icon.svg') no-repeat
    center;
}
.recommend-active-icon {
  background: url('/assets/icons/recommend-active-icon.svg') no-repeat
    center;
}
.recommend-deactive-icon {
  background: url('/assets/icons/recommend-deactive-icon.svg') no-repeat
    center;
}
.brand-active-icon {
  background: url('/assets/icons/brand-active-icon.svg') no-repeat
    center;
}
.brand-deactive-icon {
  background: url('/assets/icons/brand-deactive-icon.svg') no-repeat
    center;
}
.collection-active-icon {
  background: url('/assets/icons/collection-active-icon.svg') no-repeat
    center;
}
.collection-deactive-icon {
  background: url('/assets/icons/collection-deactive-icon.svg')
    no-repeat center;
}
.comm-active-icon {
  background: url('/assets/icons/comm-active-icon.svg') no-repeat center;
}
.comm-deactive-icon {
  background: url('/assets/icons/comm-deactive-icon.svg') no-repeat
    center;
}
.my-active-icon {
  background: url('/assets/icons/my-active-icon.svg') no-repeat center;
}
.my-deactive-icon {
  background: url('/assets/icons/my-deactive-icon.svg') no-repeat center;
}

.gift-active-icon {
  background: url('/assets/icons/gift-active-icon.svg') no-repeat center;
}
.gift-deactive-icon {
  background: url('/assets/icons/gift-deactive-icon.svg') no-repeat center;
}

.course-active-icon {
  background: url('/assets/icons/course-active-icon.svg') no-repeat center;
}
.course-deactive-icon {
  background: url('/assets/icons/course-deactive-icon.svg') no-repeat center;
}