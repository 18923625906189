.modal-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}
.modal-wrapper.alert {
  z-index: 101;
}

.modal-wrapper .modal-contents {
  position: absolute;
  /* bottom: -500px; */
  width: 375px;
  padding: 0 20px;
  -webkit-overflow-scrolling: touch;
}
.modal-wrapper .modal-contents.active {
  /* bottom: 0;
    transition: 5s; */
  animation: slideUp 0.5s ease;
}

.modal-wrapper .modal-contents.comment-m {
  /* height: 200px; */
}
.modal-wrapper .modal-contents.filter,
.modal-wrapper .modal-contents.category {
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 20px 20px;
  width: 335px;
}

.modal-wrapper .modal-contents.category {
  width: 375px;
  z-index: 12;
  overflow: auto;
}

.modal-wrapper .modal-contents.category.close {
  transform: translateY(500px);
}

.modal-wrapper .modal-contents.filter .filter-modal-header,
.modal-wrapper .modal-contents.category .filter-modal-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-wrapper .modal-contents.category .filter-modal-header.with-padding {
  margin: 0 20px 20px 20px;
}
.modal-wrapper .modal-contents.filter .filter-modal-header .gray-close-icon,
.modal-wrapper .modal-contents.category .filter-modal-header .gray-close-icon {
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0;
}
.modal-wrapper .modal-contents.filter .filter-modal-header .title,
.modal-wrapper .modal-contents.category .filter-modal-header .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #000000;
}
.modal-wrapper .modal-contents.category .filter-modal-header .init {
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.02em;
  position: absolute;
  right: 0;
}

.modal-wrapper .modal-contents.filter .filter-modal-body {
  display: flex;
  margin-top: 43px;
  margin-bottom: 40px;
}
.modal-wrapper .modal-contents.category .category-modal-body {
  margin: 0;
}
.modal-wrapper .modal-contents.category .category-modal-body.no-temp .message {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #9f9ca3;
}
.modal-wrapper
  .modal-contents.category
  .category-modal-body.no-temp
  .sub-message {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #9f9ca3;
  margin-top: 10px;
}

.modal-wrapper .modal-contents.category .category-modal-body li {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-wrapper .modal-contents.category .category-modal-body li + li {
  border-top: 1px solid #f7f7fc;
}

.modal-wrapper .modal-contents.category .category-modal-body li.active {
  color: #000000;
  font-weight: 600;
}
.modal-wrapper .modal-contents.filter .filter-modal-footer .retry-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}
.modal-wrapper
  .modal-contents.filter
  .filter-modal-footer
  .black-filled:disabled {
  background: #e9e7ec;
}
.modal-wrapper
  .modal-contents.filter
  .filter-modal-footer
  .retry-wrapper
  .retry-icon {
  width: 12px;
  height: 12px;
}
.modal-wrapper .modal-contents.filter .filter-modal-footer .retry-wrapper .txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  margin-left: 5px;
  color: #000000;
}

.modal-wrapper .modal-contents .comment-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  border-radius: 10px;
}
.modal-wrapper .modal-contents .comment-modal-btn.my-page {
  flex-direction: column;
  background: inherit !important;
  padding-bottom: 30px;
}
.modal-wrapper .modal-contents .comment-modal-btn.cancel {
  margin-bottom: 30px;
  height: 50px;
}
.modal-wrapper .modal-contents .comment-modal-btn.edit {
  margin-bottom: 10px;
}
.modal-wrapper .modal-contents .comment-modal-btn.report {
  margin-bottom: 10px;
}
.modal-wrapper .modal-contents .comment-modal-btn .cancel-txt {
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.02em;
  /* gray5 */
  color: #78737d;
}
.modal-wrapper .modal-contents .comment-modal-btn .profile-txt {
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.02em;
  /* gray5 */
  color: #000000;
}
.modal-wrapper .modal-contents .comment-modal-btn .edit-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
}
.modal-wrapper .modal-contents .comment-modal-btn .edit-body button {
  height: 50px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */
  letter-spacing: -0.02em;
}

.modal-wrapper .modal-contents .comment-modal-btn .edit-body .edit-comment {
  color: #000000;
  border-bottom: 1px solid #e9e7ec;
}

.modal-wrapper .modal-contents .comment-modal-btn .edit-body .delete-comment {
  /* main-red */
  color: #ff3e48;
}

/* filter modal */

.filter-modal-body .reaction-icon {
  width: 65px;
  height: 65px;
  border-radius: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */
  text-align: center;
  letter-spacing: -0.02em;
  /* black */
  color: #000000;
  margin-left: 20px;
}
.filter-modal-body .reaction-icon:nth-child(1) {
  margin-left: 0;
}
.filter-modal-body .reaction-icon .icon {
  width: 17px;
  height: 17px;
  margin-bottom: 8px;
}
.filter-modal-body .reaction-icon.active {
  background: #000;
  border: 0;
}

.filter-modal-body .reaction-icon.active.best {
  color: #ff4b84;
}
.filter-modal-body .reaction-icon.active.like {
  color: #ff9c40;
}
.filter-modal-body .reaction-icon.active.normal {
  color: #1ed47d;
}
.filter-modal-body .reaction-icon.active.bad {
  color: #907dff;
}

.filter-modal-body.sort {
  flex-direction: column;
}
.filter-modal-body.sort .sort-filter {
  display: flex;
}
.filter-modal-body.sort .sort-filter button {
  width: 165px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.02em;
  text-align: center;
  margin-left: 5px;
}
.filter-modal-body.sort .sort-filter button:nth-child(1) {
  margin-left: 0;
}
.filter-modal-body.sort .sort-filter button.active {
  background: #000;
  color: #fff;
}
.filter-modal-body.sort .random-filter {
  margin-top: 10px;
}
.filter-modal-body.sort .random-filter .random {
  width: 100%;
  height: 30px;
  border: 1px solid #000;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-modal-body.sort .random-filter .random.active {
  background: #000;
  color: #fff;
}
.filter-modal-body.sort .random-filter .random .dice-icon {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-right: 10px;
}

/* brand modal */
.brand-modal-body {
  padding: 43px 0 50px 0;
}
.brand-modal-body .search-wrapper {
  position: relative;
  width: 100%;
}
.brand-modal-body .search-wrapper input {
  width: 100%;
  height: 42px;
  border: 1px solid #e9e7ec;
  border-radius: 21px;
  text-align: left;
  padding: 0 20px;
}
.brand-modal-body .search-wrapper input::placeholder {
  color: #9f9ca3;
  font-weight: 400;
}
.brand-modal-body .search-wrapper .search-gray-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 13px;
}
.brand-modal-body .search-result {
  margin-top: 30px;
  height: 135px;
  overflow: auto;
}
.brand-modal-body .search-result li {
  height: 30px;
  display: flex;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
}
.brand-modal-body .search-result li .brand-logo {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #000;
}
.brand-modal-body .search-result li .brand-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  margin: 0 15px 0 10px;
}
.brand-modal-body .search-result li.active .brand-title-wrapper {
  color: #ff4b84;
}
.brand-modal-body .search-result li.active .brand-title {
  color: #ff4b84;
}
.brand-modal-body .search-result li .brand-category {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.04em;
  color: #78737d;
}

/* address modal */
.address-modal-body {
  padding: 43px 0 40px 0;
  display: flex;
}
.address-modal-body .address-selector {
  position: relative;
  width: 160px;
  height: 42px;
  cursor: pointer;
}
.address-modal-body .address-selector input {
  width: 160px;
  height: 42px;
  border: 1px solid #e9e7ec;
  border-radius: 21px;
  padding: 14px 20px;
  cursor: pointer;
  color: #9f9ca3;
}
.address-modal-body .address-selector input.has-child {
  color: #000;
}
.is-online {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.is-online input {
  /* width: 16px;
    height: 16px;
    margin-right: 5px;
    cursor: pointer; */
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.is-online label {
  position: relative;
  cursor: pointer;
}

.is-online label:before {
  content: '';
  display: inline-block;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #e9e7ec;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}
.is-online input:checked + label:before {
  background: #000;
}

.is-online input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.is-online label {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  cursor: pointer;
}

.address-modal-body .address-selector input:disabled {
  background: #f7f7fc;
}
.address-modal-body .address-selector .icon {
  position: absolute;
  right: 20px;
  bottom: 13px;
  width: 16px;
  height: 16px;
}

.modal.popup.alert {
  width: 285px;
  background: #fff;
  border-radius: 10px;
}
.modal.popup.alert.logout,
.modal.popup.alert.signout {
  height: 154px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}
.modal.popup.alert .btn.submit {
  color: #ff3e48;
  border-right: 1px solid #e9e7ec;
}
.modal.popup.alert .btn.cancel {
  color: #000;
}
.modal.popup.alert .modal-body {
  height: 104px;
  border-bottom: 1px solid #e9e7ec;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tast-tag-modal .modal-body {
  flex-direction: column !important;
}
.tast-tag-modal .modal-body .taste-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal.popup.alert .btn-wrapper {
  display: flex;
  height: 50px;
}

.modal.popup.alert .btn-wrapper .btn {
  width: 50%;
}

.toast-wrapper {
  position: fixed;
  display: flex;
  bottom: 100px;
  width: 307px;
  height: 34px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  z-index: 5;
  justify-content: center;
  align-items: center;
  background: #000;
  color: #fff;
  border-radius: 22px;
}

.toast-wrapper.position-bottom {
  bottom: 15px;
}

/* address select modal */
.address-select-modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
}
.address-select-container {
  width: 335px;
  height: 400px;
  border-radius: 10px;
  background: #fff;
}
.address-select-container .address-select-modal-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding-left: 23px;
}

.address-select-container .address-select-modal-header .gray-close-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
}

.address-select-container .address-selector-wrapper {
  overflow: auto;
  height: 312px;
}
.address-select-container .address-select-modal-body {
  padding: 0 20px;
}
.address-select-container .address-select-modal-body .address-item {
  height: 40px;
  border-bottom: 1px solid #f7f7fc;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.address-select-container .address-select-modal-body .address-item.active {
  color: #ff4b84;
}

/* temp */
.modal-wrapper .temp-modal-body {
  margin-top: 43px;
  margin-bottom: 40px;
}
.modal-wrapper .temp-modal-body .temp-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f7f7fc;
}
.modal-wrapper .temp-modal-body .temp-item .contents-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.modal-wrapper .temp-modal-body .temp-item .contents-info .pic {
  width: 30px;
  height: 40px;
  background: url('../../assets/banner/temp-dummy-img.png') no-repeat center /
    cover;
  margin-right: 8px;
}
.modal-wrapper .temp-modal-body .temp-item .contents-info .info .name {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  text-align: left;
  margin-bottom: 5px;
}
.modal-wrapper .temp-modal-body .temp-item .contents-info .info .date {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.04em;
  color: #78737d;
}
.modal-wrapper .temp-modal-body .temp-item .trash-icon-wrapper .trash-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.modal-wrapper .temp-modal-body .sub-message {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #9f9ca3;
  text-align: left;
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .modal-wrapper .modal-contents {
    width: 100% !important;
    max-height: none;
  }
  .modal-wrapper .modal-contents.community-auth-alert {
    width: calc(100% - 80px) !important;
  }
  .modal-wrapper .modal-contents.community-alert {
    /* margin: 0 20px; */
    width: calc(100% - 40px) !important;
  }
}
@keyframes slideUp {
  from {
    /* 하위 120px 에서 시작합니다. */
    transform: translateY(500px);
  }
  to {
    /* 원 위치로 이동합니다. */
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    /* 하위 120px 에서 시작합니다. */
    transform: translateY(0);
  }
  to {
    /* 원 위치로 이동합니다. */
    transform: translateY(500px);
  }
}
