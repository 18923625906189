.background {
  position: relative;
  background: #000 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.logo {
  width: 280px;
  height: 60px;
  background: url('/assets/banner/home-white-android-logo.svg') no-repeat center / cover;
}
.update_reuqest_buttons {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 40px;
}
.update_txt {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.button {
  width: 100%;
  height: 45px;
  background: #ff4b84;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 20px;
  color: #ffffff;
}
