.wrapper {
  background-color: #f7f7fc;
  padding-bottom: 56px;

  &.ios {
    padding-bottom: 86px;
  }
}

.divider {
  height: 1px;
  background: #e9e7ec;
  margin: 0 20px;
}
.items {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
  cursor: pointer;
  margin-bottom: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
  .active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }
}
.collectionViewMoreBtn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d2d0d5;
  border-radius: 5px;
  .txt {
    color: #433e48;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
  }
  .collectionViewMoreIcon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('/assets/icons/collection_arrow_more_icon.png') no-repeat
      center / cover;
  }
}

.isMobile {
  margin-top: calc(375px + var(--status-bar-height));

  &.ios {
    margin-top: calc(375px + env(safe-area-inset-top));
  }

  @media screen and (max-width: 600px) {
    margin-top: calc(100vw + var(--status-bar-height));

    &.ios {
      margin-top: calc(100vw + env(safe-area-inset-top));
    }
  }
}

.giftCardMain {
  padding: 20px 20px 0 20px;
  height: calc(100vh - 210px);
  &.ios {
    margin-top: calc(56px + env(safe-area-inset-top));
  }
  &.android {
    margin-top: calc(56px + var(--status-bar-height));
  }
  .defaultInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .defImg {
      width: 120px;
      height: 120px;
      background: #c9c9c9;
      border-radius: 4px;
    }
    .tit {
      color: var(--black, var(--color-black, #000));
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      letter-spacing: -0.48px;
      margin-top: 20px;
    }
  }
  .descriptions {
    margin: 20px 0;
    width: 100%;
    border-radius: 4px;
    background: var(--G1, #f7f7fc);
    padding: 20px 16px;
    color: var(--G6, #433e48);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    .descItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      span {
        margin-left: 10px;
      }
      &.schedule {
        span {
          color: #ff4b84;
        }
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
  .phoneNumber {
    width: 100%;
    .phoneLabel {
      color: var(--black, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.32px;
      text-align: left;
      margin-bottom: 10px;
    }
    .phone {
      input {
        width: 100%;
        display: flex;
        height: 48px;
        padding: 16px 20px;
        align-items: center;
        gap: 72px;
        align-self: stretch;
        border-radius: 5px;
        border: 1px solid var(--color-gray-2, #e9e7ec);
        background: var(--white, #fff);
      }
    }
  }
  .warning {
    margin-top: 20px;
    .warningTit {
      color: var(--G5, #78737d);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      margin-bottom: 4px;
      text-align: left;
    }
    .warningList {
      color: var(--G5, #78737d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 0 20px;
      list-style: disc;
    }
  }
  .question {
    margin-top: 20px;
    .qItem {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--black, var(--color-black, #000));
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      text-decoration-line: underline;
      span {
        margin-right: 2px;
      }
      cursor: pointer;
    }
  }
}

.bottomBtn {
  &.normal {
    position: sticky;
    bottom: 13px;
  }
  &.ex {
    margin-top: 40px;
  }
  padding: 13px 0;
  button {
    width: 100%;
    border-radius: 5px;
    background: var(--color-black, #000);
    color: var(--white, var(--color-white, #fff));
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    display: flex;
    padding: 0px 13px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    height: 50px;
    &:disabled {
      border-radius: 5px;
      background: var(--color-gray-2, #e9e7ec);
      color: var(--G4, #9f9ca3);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
    }
  }
}

.already {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--G2, #e9e7ec);
  background: #fff;
  padding: 10px 12px;
  margin-top: 20px;
  .aTit {
    color: var(--G5, #78737d);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .aLink {
    color: var(--black, var(--color-black, #000));
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

.mejangIcon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../../assets/icons/mejang-icon.svg') no-repeat center /
    cover;
  margin-left: 0 !important;
}

.cardIcon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../../assets/icons/card-icon.svg') no-repeat center /
    cover;
  margin-left: 0 !important;
}
.submitBtnWrapper {
  .noticeTxt {
    //height: 30px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: rgba(255, 75, 132, 0.80);
    color: var(--white, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    display: none;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    &.hidden {
      display: none !important;
    }
    &.show {
      display: inline-block !important;
    }
    // position: absolute;
    // top: -40px;
    // left: 32%;
    &::after {
      position: absolute;
      bottom: 55px;
      content: ' ';
      display: block;
      width: 5px;
      height: 5px;
      left: calc(50% - 2.5px);
      background: url('../../../assets/icons/pink-polygon.svg') no-repeat center /cover;
    }
  }

}