.subCurationWrapper {
  padding-bottom: 50px;
}

.subCurationItem {
  padding: 40px 0 10px 0;

  .title {
    color: var(--B, #000);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.88px;
    margin-bottom: 8px;
    text-align: left;
    white-space: pre-line;
    padding-left: 20px;
  }

  .description {
    color: var(--G5, #78737D);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.28px;
    text-align: left;
    padding-left: 20px;
  }
}
  