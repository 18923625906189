.react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    border: 0 !important;
    border-radius: 8px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    
    // 버튼 공통 스타일
    button {
      margin: 0;
      border: 0;
      outline: none;
      
      &:enabled:hover {
        cursor: pointer;
        background-color: #f8f8fa;
      }
    }
    
    .react-calendar__month-view__days__day--weekend {
        color: var(--G6, #433E48);
    }
    // 네비게이션 (년/월 이동) 영역
    .react-calendar__navigation {
      display: flex;
      height: 44px;
      margin-bottom: 1em;
      
      button {
        min-width: 44px;
        background: none;
        
        &:disabled {
          background-color: #f0f0f0;
        }
        
        &:enabled:hover,
        &:enabled:focus {
          background-color: #f8f8fa;
        }
      }
    }

    .react-calendar__navigation {
        display: flex;
        height: 44px;
        margin-bottom: 1em;
        justify-content: center;
        .react-calendar__navigation__label {
            flex: unset !important;  // 기본 flex 속성 제거
            min-width: auto !important;  // 최소 너비 제거
            padding: 0 8px;  // 좌우 여백 추가
        }
        // 년도 이동 버튼 숨기기
        .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
        .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
          display: none;
        }
        
        button {
          min-width: 44px;
          background: none;
          
          &:disabled {
            background-color: #f0f0f0 !important;
          }
          
          &:enabled:hover,
          &:enabled:focus {
            background-color: #f8f8fa;
          }
        }
    }

    // 달력 타일 (날짜) 스타일
    .react-calendar__tile {
      max-width: 100%;
      padding: 10px 6.6667px;
      background: none;
      text-align: center;
      line-height: 16px;
      position: relative;  // 추가
      height: 40px;
      font-size: 14px;
      
      &:disabled {
        //background-color: #f0f0f0 !important;
        color: #ababab;
      }
      
      &:enabled:hover,
      &:enabled:focus {
        background-color: #f8f8fa;
      }
      
      // 오늘 날짜
    //   &--now {
    //     background: #ffff76;
    //     &:enabled:hover,
    //     &:enabled:focus {
    //       background: #ffffa9;
    //     }
    //   }
      
      // 선택된 날짜
      &--active {
        background: none;  // 기존 배경색 제거
        color: #fff;      // 텍스트 색상 흰색으로
        z-index: 2;
        &::after {        // 정사각형 표시를 위한 가상 요소
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          background: #000;  // 배경색 검정으로
          transform: translate(-50%, -50%);
          width: 32px;    // 정사각형 크기
          height: 32px;   // 정사각형 크기
          border-radius: 32px;  // 필요한 경우 모서리를 둥글게 하려면 이 값을 조정
          z-index: -1;    // 텍스트 아래에 위치하도록
        }
  
        &:enabled:hover,
        &:enabled:focus {
          background: none;  // hover 시에도 배경색 제거
        }
      }
    }
    
    // 주말 색상
    
    // 요일 텍스트 스타일링
    .react-calendar__month-view__weekdays {
        color: var(--B, #000);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
      
      // 요일 텍스트 컨테이너
      .react-calendar__month-view__weekdays__weekday {
        padding: 0.5em;
        
        // abbr 태그의 밑줄 제거
        abbr {
          text-decoration: none;
        }
      }
    }
    // 다른 달의 날짜
    .react-calendar__month-view__days__day--neighboringMonth {
        color: var(--G3, #D2D0D5);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
    }
}
