
.detailWrapper {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%);
    // background: rgba(255,255,255,0.5);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-end;
    display: none;
    z-index: 3 !important;
    &.showLogin {
        display: flex;
        .logins {
            animation: showLogin 1s ease-in-out;
        }
    }
}
.logins {
    padding: 176px 32px 140px 32px;
}
.loginTxt {
    border-radius: 10px;
    background: rgba(28, 28, 28, 0.90);
    display: flex;
    padding: 30px 20px 20px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    strong {
        color: #FFF;
        text-align: center;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 140% !important; /* 25.2px */
        letter-spacing: -0.36px !important;
        width: 100%;
    }
    button {
        width: 100%;
        height: 44px;
        border-radius: 5px;
        background: #FFF;
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
    }
}


@keyframes showLogin {
    from {
        top: 600px;
    }
    to {
        top: 0;
    }
}
