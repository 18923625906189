.home-banner-content {
  position: relative;
  height: 300px;
  object-fit: cover;
}

.img-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 111.73%;

  img {
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &:before {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    height: 100px;
    /*
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    */
    content: '';
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 156px;
    /*
    background: linear-gradient(
      0,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    */
    content: '';
  }
}

.txt-wrapper {
  position: absolute;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 30px 40px;
  background-color: transparent;
  text-align: left;
  overflow: hidden;
  opacity: 0;
  margin-bottom: -20px;
  transition: margin-bottom 0.6s, opacity 0.7s 0.3s;

  .title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 130%;
    word-break: keep-all;
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    letter-spacing: -0.48px;
  }

  .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

:global(.android) {
  .img-wrapper {
    padding-bottom: calc(var(--status-bar-height) + 111.73%);
  }
}

// :global(.ios) {
//   .img-wrapper {
//     padding-bottom: 126.4%;
//   }
// }

:global(.swiper-slide.swiper-slide-active) {
  .txt-wrapper {
    margin-bottom: 0;
    opacity: 1;
  }
}
