.contents {
    padding: 40px 10px 10px 10px;
    .titleArea {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.36px;
        text-align: center;
        margin-bottom: 5px;
    }
    .txtArea {
        margin-bottom: 30px;
        color: var(--black, var(--color-black, #000));
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.36px;
    }
    .buttonArea {
        background: #fff;
    }
}
