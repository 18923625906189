.hide {
  display: none !important;
}
.collection_top {
  width: 100%;
  height: 200px;
  background: url('/assets/banner//collection_new_bg.jpeg') no-repeat center /
    cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subTitle {
  letter-spacing: -0.02em;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
}
.wrapper {
  min-height: calc(100vh - 86px - 56px);
  display: flex;
  flex-direction: column;
}
.picture_info_wrapper {
  .picture_wrapper {
    width: 100%;
    padding-bottom: 100%;
    border-radius: 4px;
  }
}

.info_wrapper {
  text-align: left;
  margin-top: 7px;
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .sub_txt {
    color: #78737d;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.24px;
  }
}
.tabsArea {
  border-top: 10px solid #f7f7fc;
  border-bottom: 1px solid #f7f7fc;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
  }
}

.tabs {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin-top: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  .tab {
    position: relative;
    background: #ffffff;
    border: 1px solid #e9e7ec;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.02em;
    padding: 8px 14px;
    // min-width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & + .tab {
      margin-left: 5px;
    }

    &.active {
      background: #000;
      border: 1px solid #000;
      color: #fff;
    }

    &.new:after {
      position: absolute;
      top: 6px;
      right: 8px;
      display: block;
      width: 4px;
      height: 4px;
      background-color: #ff4b84;
      border-radius: 50%;
      content: '';
    }
  }
}

.collectionWrap {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.no_list {
  flex: 1;
  display: flex;
  padding: 76px 0 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no_list_txt {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #78737d;
}
.setup_push {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
  padding: 7px 25px;
  border: 1px solid #000;
  border-radius: 16px;
  margin-top: 20px;
  .setup_bell {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;

  }
}

.onlyArea {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px 20px 0;
  line-height: 24px;

  input {
    display: none;
    &:checked + label::before {
      background: url('/assets/icons/check-icon.svg');
    }
  }

  .img_badge {
    padding: 5px 6px;
    margin-right: 4px;
    border-radius: 5px;
    background: url('/assets/icons/onlynew.png') no-repeat center / cover;
    font-size: 12px;
    font-weight: 800;
    line-height: 14px;
    color: #fff;
    letter-spacing: -0.24px;
  }

  label {
    display: flex;
    color: #433e48;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.28px;

    &::before {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 4px;
      background: url('/assets/icons/uncheck-icon.svg') no-repeat center / cover;
      content: '';
    }
  }
}

.list {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 40px;
  column-gap: 2px;

  &.contents {
    animation: fadeIn 1s;
  }
}
.scrapMagazineList {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 2px;
  column-gap: 2px;
  padding: 0 20px;

  &.contents {
    animation: fadeIn 1s;
  }
}
.collection_item_wrapper {
  .img_wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: calc(100% - 8px);
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .img_badge_list {
      position: absolute;
      top: 6px;
      left: 6px;
      display: flex;
    }

    .img_badge {
      background: #fff;
      color: #000;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: -0.24px;
      padding: 5px 6px;
      border-radius: 5px;

      &.close_badge {
        background: #000;
        color: #fff;
      }

      &.only {
        background: url('/assets/icons/onlynew.png') no-repeat center / cover;
        color: #fff;
      }

      & + .img_badge {
        margin-left: 4px;
      }
    }
    .pre_open {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      color: #fff;
      .pre_open_title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #e9e7ec;
        white-space: pre-line;
      }
    }

    .like_wrapper {
      z-index: 1;
      position: absolute;
      right: 6px;
      bottom: 6px;
      span {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }
  .txt_wrapper {
    padding: 10px 10px 0;
    text-align: left;
    min-height: 94px;

    .sub_txt_wrapper {
      margin-top: 6px;

      .sub_txt {
        color: #78737d;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
    .title_wrapper {
      overflow: hidden;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.4px;
      color: #000;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 44px;
    }
    .price_wrapper {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;

      .rate {
        margin-right: 3px;
        color: #ff4b84;
      }
    }
  }
}

.heart_icon {
  width: 24px !important;
  height: 24px !important;
}
.heart_empty {
  background: url('/assets/icons/collection_unscrap_icon.svg') no-repeat center /
    contain;
}
.heart_filled {
  background: url('/assets/icons/brand_list_doscrap_icon.svg') no-repeat center /
    contain;
}
.go_to_contents {
  border: 1px solid #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
  border-radius: 16px;
  // padding: 8px 30px;
  width: 147px;
  padding: 8px 0;
}
.collectionList {
  .tab {
    position: sticky;
    top: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 6;
    border-bottom: 1px solid #e9e7ec;
    background: #fff;
    .tabItem {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #9f9ca3;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      padding: 11px 0;
      cursor: pointer;
      &.active {
        color: #000;
        border-bottom: 2px solid #000;
      }
    }
  }
}

.emptyOptionAlertArea {
  .contents {
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.32px;
      padding: 20px 0;
      margin: 0 20px;
      text-align: left;
      border-bottom: 1px solid #e9e7ec;
    }
    .options {
      .optionTitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        letter-spacing: -0.32px;
        padding: 20px 20px 2px 20px;
        text-align: left;
      }
      .optionSelectorWrapper {
        padding: 0 20px;
      }
    }
    .phoneNumberInput {
      margin-top: 40px;
      .phoneNumberInputTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        letter-spacing: -0.32px;
        padding: 0 20px 10px 20px;
        text-align: left;
      }
      .phoneNumberInputForm {
        padding: 0 20px;
        input {
          border-radius: 5px;
          border: 1px solid #e9e7ec;
          background: #fff;
          width: 100%;
          height: 48px;
          padding: 16px 20px;
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            letter-spacing: -0.28px;
          }
          &:focus {
            outline: none;
            border: 1px solid #9f9ca3;
          }
          &.error_border {
            border: 1px solid #ff3e48;
          }
        }
        .error {
          color: #ff3e48;
          text-align: left;
          margin-top: 15px;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: -0.02em;
          display: flex;
          justify-content: flex-start;
          padding: 0 20px;
          & > span {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 8px;
          }
        }
        ul {
          padding: 0 20px;
          margin-top: 12px;
          li {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: -0.48px;
            color: #433e48;
            text-align: left;
          }
        }
      }
    }
  }
  .btnWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .cancelBtn {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border: 1px solid #000;
      border-radius: 5px;
    }
    .applyBtn {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background: #000;
      color: #fff;
      border-radius: 5px;
      margin-left: 5px;
      &:disabled {
        background: #e9e7ec;
      }
    }
  }
}

.scrapMagazineItem {
  overflow: hidden;
  position: relative;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  .top {
    display: flex;
    justify-content: flex-end;
    padding: 6px;
  }
  .bottom {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding: 12px;
    .title {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      white-space: pre-line;
      text-align: left;
    }
  }

  &:after {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 62px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    content: '';
  }
}

.brandCategoryArea {
  display: flex;
  overflow-x: auto;
  padding: 16px 0;
  white-space: nowrap;
}

.categoryList {
  padding: 0 20px;
}

.categoryItem {
  padding: 6px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.28px;
  border: 1px solid #e9e7ec;
  border-radius: 30px;

  & + .categoryItem {
    margin-left: 5px;
  }

  &.on {
    color: #fff;
    background-color: #000;
  }
}

.brandArea {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 30px;
  column-gap: 2px;
  padding: 0 20px 40px;
}

.brand {
  position: relative;

  .bookmark {
    position: absolute;
    top: 6px;
    right: 4px;
  }
}

.bookmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('/assets/icons/brand_list_unscrap_icon.svg') no-repeat center /
    contain;

  &.active {
    background: url('/assets/icons/brand_list_doscrap_icon.svg') no-repeat
      center / contain;
  }
}

.waitingArea {
  .txtWrapper {
    margin: 40px 20px 0;
    border-bottom: 1px solid #e9e7ec;

    &:before {
      display: block;
      width: 36px;
      height: 36px;
      margin: 0 auto 16px;
      background: url('/assets/icons/ok-icon.svg') no-repeat center / contain;
      content: '';
    }
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.4px;
  }

  .desc {
    padding: 16px 0 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
    color: #78737d;
    white-space: pre-line;
  }

  .list_title {
    margin: 40px 20px 0;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.36px;
  }

  .collectionViewMoreBtn {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d2d0d5;
    border-radius: 5px;
    .txt {
      color: #433e48;
      font-size: 16px;
      font-weight: 600;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
    }
    .collectionViewMoreIcon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url('/assets/icons/collection_arrow_more_icon.png') no-repeat
        center / cover;
    }
  }
}

.promotionItem {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 62.16%;
  background-color: #eff1f3;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & + .promotionItem {
    margin-top: 4px;
  }

  .txtWrap {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 16px;

    .title {
      position: relative;
      overflow: hidden;
      display: -webkit-box;
      width: 100%;
      margin-top: auto;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: -0.4px;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-align: left;
      padding-right: 20px;
      white-space: pre-line;

      .icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
      }
    }

    .subTitle {
      display: flex;
      justify-content: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -0.24px;
      color: #000;

      &:before {
        display: block;
        width: 10px;
        height: 10px;
        background-color: #000;
        margin: 3px 4px 0 0;
        content: '';
      }
    }

    &.white {
      .title,
      .subTitle {
        color: #fff;
      }

      .subTitle:before {
        background-color: #fff;
      }
    }
  }
}

.btnWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.32px;
    border: 1px solid #d2d0d5;

    .icon {
      width: 16px;
      height: 16px;
      display: inline-block;
      background: url('/assets/icons/find_map_btn_icon.svg') no-repeat center /
        cover;
      margin-left: 8px;
    }

    & + .btn {
      margin-top: 8px;
    }

    &:hover {
      border-color: #000;
    }
  }

  .bizBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    letter-spacing: -0.32px;
    border: 1px solid #E9E7EC;
    border-radius: 4px;

    .icon {
      width: 12px;
    height: 12px;
    display: inline-block;
    background: url('/assets/icons/find_map_btn_icon.svg') no-repeat center / cover;
    margin-left: 6px;
    }
  }
}

.other_collection {
  border-radius: 5px;
  border: 1px solid var(--color-gray-3, #D2D0D5);
  background: var(--color-white, #FFF);
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: var(--6, var(--color-gray-6, #433E48));
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
}
.other_arrow_left {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../assets/icons/find_map_btn_icon.svg') no-repeat center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
