.collectionWrap {
    width: 100%;
    height: 100%;
    
    .no_list {
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
    .listArea {
      width: 100%;
      height: 100%;
      
      .list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 2px;
        // padding: 20px;
        
        .img_wrapper {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 0;
          padding-bottom: calc(100% - 8px);
            
          img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
          }
          .like_wrapper {
              z-index: 1;
              position: absolute;
              right: 6px;
              bottom: 6px;
              span {
                display: inline-block;
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
          }
        }
      }
    }
  }


.heart_empty {
    background: url('/assets/icons/collection_unscrap_icon.svg') no-repeat center /
      contain;
}
.heart_filled {
    background: url('/assets/icons/brand_list_doscrap_icon.svg') no-repeat center /
        contain;
}
.courseTitle {
    color: var(--B, #000);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: -0.8px;
    text-align: left;
    padding: 20px;
}

.item_wrapper {
    .txt_wrapper {
        padding: 10px 10px 0 10px;
        h3 {
            text-align: left;
            overflow: hidden;
            color: var(--black, #000);
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.32px;
        }
        p {
            overflow: hidden;
            color: var(--5, #78737D);
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 18.2px */
            letter-spacing: -0.28px;
            text-align: left;
            margin-top: 6px;
        }
    }
}