
.price_wrapper {
    display: flex;
    padding: 25px 0;
    justify-content: space-between;
    margin-bottom: 13px;
    .price {
      color: var(--b, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
    .counter {
      display: flex;
      align-items: center;
      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 30px;
        background: #fff;
        margin: 0 7px;
        border: 1px solid #e9e7ec;
      }
    }
  }

.options_wrapper {
  margin-top: 15px;
}

.disable_button {
  background: #E9E7EC !important;
  color: #fff !important;
}