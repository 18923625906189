.hide {
    display: none;
}
.address {
    padding: 20px 0 !important;
}
.address_selector {
    display: flex;
    height: 408px;
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    border-top: 1px solid #F7F7FC;
    border-bottom: 1px solid #E9E7EC;
}
.address1_group {
    width: 140px;
    background: #F7F7FC;
    overflow: auto;
    div {
            position: relative;
            padding: 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            text-align: left;
            letter-spacing: -0.02em;
            height: 52px !important;
            &.toggled {
                font-weight: 600;
                background: #fff;
                &::after {
                    content: '';
                    position: absolute;
                    right: 10px;
                    top: 45%;
                    display: block;
                    width: 5px;
                    height: 10px;
                    background: url('/assets/icons/address_indicator.svg') no-repeat center / cover;
                }
            }
        }

    &::-webkit-scrollbar {
        display: none;
    }
}
.address2_group {
    flex: 1;
    padding: 0 20px;
    overflow: auto;
    ul {
        li {
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color : #9F9CA3;
            height: 52px !important;
            text-align: left;
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
.address_footer {
    padding: 0 20px;
}
.famousArea {
    display: flex;
    flex-wrap: wrap;
    .areaBtn {
        padding: 24px 0;
        border-radius: 4px;
        border: 1px solid var(--G2, #E9E7EC);
        color: var(--G4, #9F9CA3);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        min-width: 45%;
        // flex: 1;
        letter-spacing: -0.32px;
        margin: 0 8px 8px 0;
        &.activeArea {
            border: 1px solid var(--B, #000);
            color: #000;
        }
    }
}
.noAddressList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    strong {
        color: var(--B, #000);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;
        margin: 10px 0;
    }
    p {
        color: var(--gray5, #78737D);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.80px;
        margin-bottom: 20px;
    }
    button {
        border-radius: 5px;
        border: 1px solid var(--2, #E9E7EC);
        background: var(--color-white, #FFF);
        color: var(--6, #433E48);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
        padding: 13px 12px;
    }
    .registerBrand {
        color: var(--4, #9f9ca3);
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
        letter-spacing: -0.28px;
        border: none;
        .dropdownIcon {
          display: inline-block;
          width: 12px;
          height: 12px;
          background: url('/assets/icons/brand_info_area_selection_down.svg')
            no-repeat center / cover;
        }
        .registerArrow {
            flex: none;
            display: inline-block;
            width: 12px;
            height: 12px;
            background: url('/assets/icons/arrow_dropdown.svg') no-repeat center /
              cover;
        }
    }    
}